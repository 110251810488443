import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import MarkdownContent from "../components/MarkdownContent";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";

// eslint-disable-next-line
export const ServicesPageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
  main,
  secondary,
  tertiary
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div className="content">
      <FullWidthImage img={heroImage} title={title} />
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-full">
                <h3 className="has-text-weight-semibold is-size-2">
                  {heading}
                </h3>
                <p>{description}</p>
              </div>
            </div>
            <div className="columns">
              <div className="column is-full">
                <div className="columns">
                  <div className="column is-three-quarters">
                    <h3 className="has-text-weight-semibold is-size-3">
                      {main.heading}
                    </h3>
                    <MarkdownContent content={main.description} />
                  </div>
                  <div className="column">
                    <PreviewCompatibleImage imageInfo={main.image} />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-full">
                <div className="columns">
                  <div className="column">
                    <PreviewCompatibleImage imageInfo={secondary.image} />
                  </div>
                  <div className="column is-three-quarters">
                    <h3 className="has-text-weight-semibold is-size-3">
                      {secondary.heading}
                    </h3>
                    <MarkdownContent content={secondary.description} />
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-full">
                <div className="columns">
                  <div className={"column " + (tertiary.image.image === null ? '' : 'is-three-quarters')}>
                    <h3 className="has-text-weight-semibold is-size-3">
                      {tertiary.heading}
                    </h3>
                    <MarkdownContent content={tertiary.description} />
                  </div>
                  { tertiary.image.image &&
                      <div className="column">
                        <PreviewCompatibleImage imageInfo={tertiary.image} />
                      </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ServicesPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  secondary: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  tertiary: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

const ServicesPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ServicesPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        main={frontmatter.main}
        secondary={frontmatter.secondary}
        tertiary={frontmatter.tertiary}
      />
    </Layout>
  );
};

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ServicesPage;

export const servicesPageQuery = graphql`
  query ServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        description
        main {
          heading
          description
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 300, quality: 64, layout: CONSTRAINED)
              }
            }
          }
        }
        secondary {
          heading
          description
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 300, quality: 92, layout: CONSTRAINED)
              }
            }
          }
        }
        tertiary {
          heading
          description
          image {
            alt
            image {
              childImageSharp {
                gatsbyImageData(width: 300, quality: 92, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`;
